import { CONFIG } from "../../config";
import { Link } from "react-router-dom";

const HomeView = () => {
  return (
    <div>
      <div
        className="
                    flex
                    mt-72
                    flex-row
                    justify-center
                "
      >
        <button
          className="
                        text-white
                        border-4
                        bg-opacity-70
                        bg-blue-800
                        border-blue-800
                        hover:bg-blue-800
                        font-bold
                        rounded-xl
                        py-2
                        px-4
                        m-12
                    "
          onClick={() => window.location.replace(CONFIG.DISCORD_INVITE_LINK)}
        >
          Invite Craft Master Retail to Discord
        </button>
        {/*<button
          className="
                        text-white
                        border-4
                        bg-opacity-20
                        bg-green-800
                        border-green-800
                        hover:bg-green-800
                        font-bold
                        rounded-xl
                        py-2
                        px-5
                        m-12
                    "
          onClick={() =>
            window.location.replace(CONFIG.CLASSIC_DISCORD_INVITE_LINK)
          }
        >
          Invite Craft Master Classic to Discord
        </button>*/}
      </div>
      {/* Add a link to the TOS page */}
      <footer className="text-center py-4 mt-[280px]">
        <Link to="/TermsOfService" className="text-blue-600 hover:underline">
          Terms of Service
        </Link>
      </footer>
    </div>
  );
};

export default HomeView;