import ContentBox from "../../components/ContentBox";
import BotLogo from '../../resources/wcm-bot-profile.png';

const LoginProgress = () => {
    return (
        <div
            className='
                flex
                mt-40
                flex-col
                items-center
                space-y-10
            '
        >
            <ContentBox>
                <p className="
                        text-white
                        font-bold
                        text-2xl
                    "
                >
                    Connecting Your Account...
                </p>
                <br />
                <div className="
                        animate-bounce
                    "
                >
                    <img 
                        className="
                            w-20
                            h-20
                            rounded-full
                        "
                        src={BotLogo}
                    />
                </div>
            </ContentBox>
        </div>
    );
}

export default LoginProgress;