import ContentBox from "../../components/ContentBox";
import { CONFIG } from "../../config";

const CLIENT_ID: string = CONFIG.DISCORD_TEST_BATTLENET.CLIENT_ID;
const GRANT_TYPE: string = CONFIG.BATTLENET.GRANT_TYPE;
const REDIRECT_URI: string = CONFIG.DISCORD_TEST_BATTLENET.REDIRECT_URI;
const CODE: string = CONFIG.BATTLENET.CODE;
const SCOPES: string = CONFIG.BATTLENET.SCOPES;

function callBattlenetAuthURL() {
    const STATE = window.crypto.randomUUID();
    const AUTH_URI: string = `https://${CONFIG.BATTLENET.REGION}.battle.net/oauth/authorize?`
        + `client_id=${CLIENT_ID}`
        + `&state=${STATE}`
        + `&grant_type=${GRANT_TYPE}`
        + `&redirect_uri=${REDIRECT_URI}`
        + `&response_type=${CODE}`
        + `&scope=${SCOPES}`;

    window.location.replace(AUTH_URI);
}

const DiscordBattlenetRedirectTest = () => {
    return (
        <div
            className='
                flex
                mt-40
                flex-col
                items-center
                space-y-10
            '
        >
            <ContentBox>
                <p className="
                        text-white
                        font-bold
                        text-2xl
                    "
                >
                    Backend Redirect Test
                </p>
                <p className="
                        text-white
                        text-center
                        max-w-lg
                        text-md
                    "
                >
                    Clicking the button will send a redirect URI telling Battlenet to redirect to 'http://localhost:3000/battlenet_redirect' with query parameters containing the authorization_code and state. <b>This page is for testing purposes only.</b>
                </p>
                <button className="
                        text-white
                        font-bold
                        bg-blue-600
                        hover:bg-blue-800
                        rounded-xl
                        p-1
                        px-3
                    "
                    onClick={callBattlenetAuthURL}
                >
                    Redirect to Blizzard Login
                </button>
            </ContentBox>
        </div>
    );
}

export default DiscordBattlenetRedirectTest;