import React from "react";

const TermsOfService = () => {
  return (
    <div className="flex justify-center">
      <div className="p-8 w-2/3 text-white flex flex-col">
        <h1 className="text-3xl font-bold self-center">Terms of Service</h1>
        <div className="mt-4 space-y-4">
          <h2 className="text-2xl font-semibold">1. Introduction</h2>
          <p>
            Welcome to Craft Master, hereby referred to as ("the Bot"). By using
            this Bot, you agree to these Terms of Service ("ToS"). If you do not
            agree to these terms, please do not use the Bot. This ToS is a legal
            agreement between you (the "User") and Craft Master ("the Bot").
          </p>

          <h2 className="text-2xl font-semibold">2. Use of the Bot</h2>
          <h3 className="text-xl font-semibold">2.1. Eligibility</h3>
          <p>
            By using the Bot, you represent that you are at least 13 years of
            age. If you are under the age of 13, you must have permission from a
            parent or legal guardian to use the Bot.
          </p>

          <h3 className="text-xl font-semibold">2.2. License</h3>
          <p>
            The Developer grants you a non-exclusive, non-transferable,
            revocable license to use the Bot for personal, non-commercial
            purposes in accordance with this ToS.
          </p>

          <h3 className="text-xl font-semibold">2.3. Prohibited Uses</h3>
          <p>
            You agree not to:
            <ul className="list-disc ml-5 mt-2">
              <li>Use the Bot for any illegal or unauthorized purpose.</li>
              <li>
                Exploit, harm, or attempt to exploit or harm others in any way.
              </li>
              <li>
                Distribute, modify, or create derivative works of the Bot
                without permission.
              </li>
              <li>Interfere with or disrupt the Bot's services or servers.</li>
              <li>Attempt to reverse-engineer the Bot and its code.</li>
              <li>
                Abuse API rate limits, which could disrupt the Bot’s operation.
              </li>
              <li>
                Engage in any activity that may disrupt the experience of other
                users.
              </li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold">
            <div id="privacy-policy" className="privacy-policy-anchor"></div>
            3. Data Collection and Privacy
          </h2>
          <h3 className="text-xl font-semibold">3.1. Data Collection</h3>
          <p>
            The Bot may collect certain data as part of its operation, including
            but not limited to World of Warcraft character names, character
            data, character profession data, Discord usernames, Discord
            messages, and Discord server information. This data is used solely
            for the purpose of providing the Bot’s services.
          </p>

          <h3 className="text-xl font-semibold">3.2. Privacy</h3>
          <p>
            We respect your privacy and are committed to protecting your
            personal information. The data collected will not be shared with
            third parties except as required by law.
          </p>

        <h3 className="text-xl font-semibold">3.3. Data Retention</h3>
        <p>
          Data collected by the Bot may be retained for a period necessary to fulfill the Bot's functions. You may request the deletion of your data by contacting the Developer, via email at{' '} 
          <a href="mailto:info@craftmaster.io" className="text-sky-400 underline font-bold">info@craftmaster.io</a>, or on Discord via our{' '}
          <a href="https://discord.gg/Q9E78J9Pss" className="text-sky-400 underline font-bold" target="_blank" rel="noopener noreferrer">Discord Community</a>.
        </p>

          <h2 className="text-2xl font-semibold">4. Intellectual Property</h2>
          <h3 className="text-xl font-semibold">4.1. Ownership</h3>
          <p>
            All content, features, and functionality of the Bot, including but
            not limited to text, graphics, logos, icons, scripts, code,
            software, and the design of the Bot, are the exclusive property of
            Craft Master and are protected by international copyright,
            trademark, patent, trade secret, and other intellectual property or
            proprietary rights laws.
          </p>

          <h3 className="text-xl font-semibold">4.2. License to Use</h3>
          <p>
            You are granted a limited, non-exclusive, non-transferable, and
            revocable license to use the Bot solely for personal or
            non-commercial purposes, subject to the terms of this ToS. You agree
            not to copy, modify, create derivative works, reverse-engineer,
            decompile, or disassemble any part of the Bot or its underlying
            code, except as expressly permitted by applicable law.
          </p>

          <h3 className="text-xl font-semibold">4.3. User-Generated Content</h3>
          <p>
            By submitting, posting, or displaying content (including, but not
            limited to text, messages, images, or other data) to the Bot, you
            grant Craft Master a worldwide, non-exclusive, royalty-free,
            fully-paid, transferable, sublicensable license to use, display,
            reproduce, modify, adapt, and distribute such content in connection
            with the operation and promotion of the Bot.
          </p>

          <h3 className="text-xl font-semibold">4.4. Trademarks</h3>
          <p>
            Any trademarks, service marks, logos, and trade names associated
            with the Bot are the trademarks of Craft Master. You agree not to
            use or display such trademarks in any way without prior written
            permission from the Developer.
          </p>

          <h2 className="text-2xl font-semibold">5. Limitation of Liability</h2>
          <h3 className="text-xl font-semibold">5.1. No Warranties</h3>
          <p>
            The Bot is provided "as is" and "as available" without warranties of
            any kind, either express or implied, including but not limited to
            the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement. There is no guarantee for uptime or
            availability.
          </p>

          <h3 className="text-xl font-semibold">
            5.2. Limitation of Liability
          </h3>
          <p>
            In no event shall the Developer be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss
            of profits or revenues, whether incurred directly or indirectly, or
            any loss of data, use, goodwill, or other intangible losses,
            resulting from (i) your use of or inability to use the Bot; (ii) any
            unauthorized access to or use of our servers and/or any personal
            information stored therein; (iii) any bugs, viruses, trojan horses,
            or the like that may be transmitted to or through our services by
            any third party; or (iv) any errors or omissions in any content or
            for any loss or damage of any kind incurred as a result of your use
            of any content posted, emailed, transmitted, or otherwise made
            available via the Bot.
          </p>

          <h2 className="text-2xl font-semibold">6. Termination</h2>
          <h3 className="text-xl font-semibold">6.1. Termination by User</h3>
          <p>You may stop using the Bot at any time.</p>

          <h3 className="text-xl font-semibold">
            6.2. Termination by Developer
          </h3>
          <p>
            The Developer reserves the right to terminate or suspend your access
            to the Bot at any time, with or without notice, for conduct that
            violates this ToS or is harmful to other users, the Developer, or
            third parties.
          </p>

        <h2 className="text-2xl font-semibold">7. Changes to the Terms of Service</h2>
        <p>
          The Developer reserves the right to modify or replace these ToS at any time. If changes are made, the Developer will notify users via the <a href="https://discord.gg/Ydw2CYykJv" className="text-sky-400 underline font-bold" target="_blank" rel="noopener noreferrer">Updates channel</a> in Discord. Continued use of the Bot after any such changes constitutes your acceptance of the new ToS.
        </p>

          <h2 className="text-2xl font-semibold">8. Governing Law</h2>
          <p>
            This Agreement shall be governed by and interpreted in accordance
            with the laws of the United States of America, without regard to its
            conflict of law principles.
          </p>

        <h2 className="text-2xl font-semibold">9. Contact Information</h2>
        <p>
          If you have any questions about these ToS, please contact us via email at{' '}
          <a href="mailto:info@craftmaster.io" className="text-sky-400 underline font-bold">info@craftmaster.io</a>, or on Discord via our{' '}
          <a href="https://discord.gg/Q9E78J9Pss" className="text-sky-400 underline font-bold" target="_blank" rel="noopener noreferrer">Discord Community</a>.
        </p>
      </div>
    </div>
        </div>
  );
};

export default TermsOfService;
