const basePath: string = "/";

export const Locations: Locations = {
  HOME: `${basePath}`,
  TOS: `${basePath}tos`,
  BATTLENET_LOGIN: `${basePath}battlenet_login`,
  BATTLENET_REDIRECT: `${basePath}battlenet_redirect`,
  CLASSIC_BATTLENET_REDIRECT: `${basePath}classic_battlenet_redirect`,
  DISCORD_BATTLENET_REDIRECT_TEST: `${basePath}discord_battlenet_redirect_test`,
};

type Locations = {
  HOME: string;
  TOS: string;
  BATTLENET_LOGIN: string;
  BATTLENET_REDIRECT: string;
  CLASSIC_BATTLENET_REDIRECT: string;
  DISCORD_BATTLENET_REDIRECT_TEST: string;
};