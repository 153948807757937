import { ThemeProvider, CssBaseline } from '@mui/material';
import { muiTheme } from './muiTheme';
import Viewport from './Viewport';
import TermsOfService from "./features/tos/TermsOfService";
import Navigation from './features/navigation/Navigation';
import './App.css';
import './build.css';
import { Route, Routes } from 'react-router-dom';

function App() {
  document.title = "Craft Master"
  return (
    <div className="App">
            <ThemeProvider theme={muiTheme}>
            <CssBaseline /> {/* Ensures global resets */}
                <header className='App-header'>
                    <Navigation />    
                </header>
                <div 
                    className='
                        bg-black
                        bg-opacity-75
                        backdrop-blur-sm
                        w-full
                        min-h-screen
                    '
                >
                    <Viewport />
                    <Routes>
                    <Route path="/TermsOfService" element={<TermsOfService />} />
                    </Routes>
                </div>
            </ThemeProvider>  
        </div>  
  );
}

export default App;
