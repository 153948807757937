import { createTheme } from "@mui/material";

export const muiTheme: any = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#FFFFFF',
        light: '#42A5F5'
      }
    },
    typography: {
        allVariants: {
            color: '#FFFFFF'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundImage: 'url(./resources/bg.jpg)', /* Your image */
                    backgroundAttachment: 'fixed', /* Fix the background image */
                    backgroundPosition: 'center', /* Center the background */
                    backgroundSize: 'cover', /* Make it cover the whole viewport */
                    backgroundRepeat: 'no-repeat',
                    height: '100vh', /* Ensure the background covers full height */
                    margin: 0,
                    padding: 0,
                    overflowY: 'scroll', /* Ensure scrolling works for the text */
                }
            }
        }
    }
});