import ContentBox from "../../components/ContentBox";

const LoginError = () => {
    return (
        <div
            className='
                flex
                mt-40
                flex-col
                items-center
                space-y-10
            '
        >
            <ContentBox>
                <p className="
                        text-red-600
                        font-bold
                        text-2xl
                    "
                >
                    Something Went Wrong
                </p>
                <p className="
                        text-white
                        text-center
                        max-w-md
                        text-md
                    "
                >
                    We were unable to authenticate and link your Blizzard account. 
                    Please, try again, later.
                </p>
            </ContentBox>
        </div>
    );
}

export default LoginError;