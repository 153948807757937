import {
    AppBar,
    Box,
    Toolbar,
    Typography
} from '@mui/material';
import { Locations } from './Locations';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import BotLogo from './BotLogo';

const Navigation = () => {
    const navigate: NavigateFunction = useNavigate();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div
                className='
                    fixed
                    z-10
                    bg-black
                    w-screen
                    bg-opacity-12
                    backdrop-blur-sm
                '
            >
                <Toolbar>
                    <Box
                        sx={{
                            paddingRight: 3
                        }}
                    >
                        <BotLogo />
                    </Box>
                    <Typography 
                        variant="h6"
                        textAlign="left"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <a href="https://craftmaster.io/">Craft Master</a>
                    </Typography>
                    {/*
                    <button 
                        className='
                            text-white
                            hover:text-blue-600
                            font-bold
                            bg-transparent
                            m-3
                        '
                        onClick={() => navigate(Locations.HOME)}
                    >
                        Home
                    </button>
                    <button 
                        className='
                            text-white
                            hover:text-blue-600
                            font-bold
                            bg-transparent
                            m-3
                        '
                    >
                        Discord
                    </button>
                    */}
                    {/*
                    <button
                        className='
                            text-white
                            font-bold
                            bg-blue-600
                            hover:bg-blue-800
                            rounded-xl
                            p-1
                            px-3
                            ml-5
                        '
                        onClick={() => navigate(Locations.DISCORD_BATTLENET_REDIRECT_TEST)}
                    >
                        Backend Redirect Test
                    </button>
                    */}
                    {/*
                    <button
                        className='
                            text-white
                            font-bold
                            bg-blue-600
                            hover:bg-blue-800
                            rounded-xl
                            p-1
                            px-3
                            ml-5
                        '
                        onClick={() => navigate(Locations.BATTLENET_LOGIN)}
                    >
                        Blizzard Login
                    </button>
                    */}
                </Toolbar>
            </div>
        </Box>
    );
}

export default Navigation;