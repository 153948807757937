import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

interface BattlenetLoginState {
    isLoading: boolean;
    success: boolean|null;
    setIsLoading: (value: boolean) => void;
    setSuccess: (value: boolean) => void;
}

export const useLoginStore = create<BattlenetLoginState>()(
    (set) => ({
        isLoading: false,
        success: null,
        setIsLoading: (value) => set((state) => ({ isLoading: value })),
        setSuccess: (value) => set((state) => ({ success: value }))
    })
)