const ContentBox = ({ children }: any) => {
    return (
        <div className="
                bg-black
                bg-opacity-50
                flex
                flex-col
                rounded-md
                border
                border-white
                shadow-2xl
                p-8
                items-center
                space-y-7
            "            
        >
            {children}
        </div>
    )
}

export default ContentBox;