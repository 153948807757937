import axios from "axios";
import LoginProgress from "./LoginProgress";
import LoginSuccess from "./LoginSuccess";
import LoginError from "./LoginError";
import { useLoginStore } from "./loginStore";
import { CONFIG } from "../../config";

const BattlenetRedirect = () => {
    const isLoading = useLoginStore(state => state.isLoading);
    const setIsLoading = useLoginStore(state => state.setIsLoading);
    const success = useLoginStore(state => state.success);
    const setSuccess = useLoginStore(state => state.setSuccess);

    const sendAuthDataToServer = async (code: string, state: string) => {
        setIsLoading(true);
        const { data } = await axios.post(CONFIG.RECV_AUTH_DATA_ENDPOINT, {
            code: code,
            state: state
        })
        
        if (data.success === true) {
            setIsLoading(false);
            setSuccess(true);
        } else {
            setIsLoading(false);
            setSuccess(false);
        }
    }

    let contentToRender = null;

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code') && urlParams.has('state')) {
        const authCode = urlParams.get('code');
        const authState = urlParams.get('state');

        if (authCode != null && authState != null) {
            if (isLoading === true) {
                contentToRender = (<LoginProgress />);
            } else {
                if (success === true) {
                    contentToRender = (<LoginSuccess />);
                } else if (success === false) {
                    contentToRender = (<LoginError />);
                } else {
                    sendAuthDataToServer(authCode, authState);
                }
            }
        } else {
            contentToRender = (<LoginError />);
        }
    } else {
        contentToRender = (<LoginError />);
    }

    return (
        <div>
            {contentToRender}
        </div>
    );
}

export default BattlenetRedirect;