
export const CONFIG = {
    BATTLENET: {
        REGION: "us",
        CLIENT_ID: "90affd82757d4e1da67b8d02b1cb7214",
        REDIRECT_URI: "https://craftmaster.io/battlenet_redirect", // React endpoint.
        GRANT_TYPE: "authorization_code",
        CODE: "code",
        SCOPES: "wow.profile"
    },
    DISCORD_INVITE_LINK: "https://discord.com/api/oauth2/authorize?client_id=1171349749509668884&permissions=2147568640&scope=bot",
    CLASSIC_DISCORD_INVITE_LINK: "https://discord.com/api/oauth2/authorize?client_id=1203898220036489326&permissions=2147568640&scope=bot",
    DISCORD_TEST_BATTLENET: {
        CLIENT_ID: "90affd82757d4e1da67b8d02b1cb7214",
        REDIRECT_URI: "https://craftmaster.io/battlenet_redirect", // React endpoint.
    },
    RECV_AUTH_DATA_ENDPOINT: "https://craftmaster.io:3001/battlenet_redirect/", // Express endpoint.
    RECV_CLASSIC_AUTH_DATA_ENDPOINT: "https://craftmaster.io:3004/classic_battlenet_redirect/", // Express endpoint.
};
