import ContentBox from "../../components/ContentBox";

const LoginSuccess = () => {
    return (
        <div
            className='
                flex
                mt-40
                flex-col
                items-center
                space-y-10
            '
        >
            <ContentBox>
                <p className="
                        text-white
                        font-bold
                        text-2xl
                    "
                >
                    Login Successful
                </p>
                <p className="
                        text-white
                        text-center
                        max-w-lg
                        text-md
                    "
                >
                    You've successfully connected your Blizzard account. Craft Master now
                    has access to your character(s)' profession data.
                </p>
            </ContentBox>
        </div>
    );
}

export default LoginSuccess;