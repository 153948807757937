import { Route, Routes } from "react-router-dom";
import { Container, Toolbar } from "@mui/material";
import HomeView from "./features/home/HomeView";
import BattlenetLogin from "./features/battlenet_login/BattlenetLogin";
import { Locations } from "./features/navigation/Locations";
import BattlenetRedirectBackendTest from "./features/discord_battlenet_redirect_test/DiscordBattlenetRedirectTest";
import BattlenetRedirect from "./features/battlenet_redirect/BattlenetRedirect";
import ClassicBattlenetRedirect from "./features/battlenet_redirect/ClassicBattlenetRedirect";
import TermsOfService from "./features/tos/TermsOfService";

const Viewport = () => {
  return (
    <Container>
      {/*Toolbar is used to drop the Viewport below the Navigation AppBar*/}
      <Toolbar />
      <br />
      <Routes>
        <Route path={Locations.HOME} element={<HomeView />} />
        <Route path={Locations.TOS} element={<TermsOfService />} />
        {/*
                <Route path={Locations.BATTLENET_LOGIN} element={<BattlenetLogin />} />
                */}

        {/*
                <Route path={Locations.BATTLENET_LOGIN} element={<BattlenetLogin />} />
                */}
        <Route
          path={Locations.BATTLENET_REDIRECT}
          element={<BattlenetRedirect />}
        />
        <Route
          path={Locations.CLASSIC_BATTLENET_REDIRECT}
          element={<ClassicBattlenetRedirect />}
        />
        <Route
          path={Locations.DISCORD_BATTLENET_REDIRECT_TEST}
          element={<BattlenetRedirectBackendTest />}
        />
      </Routes>
    </Container>
  );
};

export default Viewport;
