import { Box } from '@mui/material';
import profileIcon from '../../resources/wcm-bot-profile.png';

const BotLogo = () => {
    const logoStyle: {
        width: number,
        height: number,
        border: string,
        borderRadius: number
    } = {
        width: 35,
        height: 35,
        border: '1px solid white',
        borderRadius: 15
    };

    return (
        <img
            src={profileIcon}
            style={logoStyle}
        />
    );
};

export default BotLogo;